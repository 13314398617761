import { useEffect, useState } from 'react'
import { NewsData, NewsTagProps } from '../components/NewsList'

export const pickItems = <T extends unknown>(arr: T[], n: number): T[] => {
  return arr.slice(0, n)
}

export function usePagination<T>(
  initialData: APIListResponse<T>,
  initialPagination: { page: number; pageSize: number } = {
    page: 1,
    pageSize: 6,
  },
  filter?: (item: ResponseData<T>) => boolean
) {
  const [list, setList] = useState(initialData.data)
  const [pagination, setPagination] = useState({
    ...initialPagination,
    pageCount: 1,
  })

  useEffect(() => {
    const data = initialData.data.filter(filter || (() => true))
    setList(data)
    setPagination({
      ...pagination,
      page: 1,
      pageCount: Math.ceil(data.length / pagination.pageSize) || 1,
    })
  }, [filter])

  return {
    list: pickItems(list, pagination.page * pagination.pageSize),
    hasMore: pagination.page < pagination.pageCount,
    next() {
      if (pagination.page >= pagination.pageCount) {
        return
      }
      setPagination({
        ...pagination,
        page: pagination.page + 1,
      })
    },
  }
}

export function sortTop(data: ResponseData<NewsData>[]) {
  return data.sort((a, b) => {
    if (a.attributes.top && !b.attributes.top) {
      return -1
    } else if (!a.attributes.top && b.attributes.top) {
      return 1
    }
    return 0
  })
}

export function sortByOrder(data: ResponseData<NewsTagProps>[]) {
  return data.sort((a, b) => {
    const aOrder = a.attributes.order
    const bOrder = b.attributes.order
    if (typeof aOrder === 'number' && typeof bOrder === 'number') {
      return aOrder - bOrder > 0 ? 1 : -1
    } else if (typeof aOrder === 'number' && typeof bOrder !== 'number') {
      return -1
    } else if (typeof aOrder !== 'number' && typeof bOrder === 'number') {
      return 1
    }

    return 0
  })
}
