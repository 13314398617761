import React, { useState } from 'react'
import { Subtitle1 } from '../Heading'
import { CircleArrowIcon } from '../Icon'
import { useTranslation } from '../../i18n'

import './index.scss'

export const useCollapse = () => {
  const { t } = useTranslation({})
  const [collapse, setCollapse] = useState(true)

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setCollapse(!collapse)
  }

  const Marker = (
    <div className="collapse-marker" onClick={onClick}>
      <Subtitle1>{collapse ? t('viewMore') : t('viewLess')}</Subtitle1>
      <CircleArrowIcon direction={collapse ? 'down' : 'top'} />
    </div>
  )

  return {
    collapse,
    Marker,
  }
}
