import React from 'react'

import './index.scss'

interface DividerProps {
  top?: number
  bottom?: number
  color?: string
  className?: string
}

export const Divider: React.FC<DividerProps> = ({ top, bottom, color }) => {
  const topPx = top ?? 16
  const bottomPx = bottom ?? 16
  const colorBg = color || 'var(--color-gray-600)'

  return (
    <div
      className="divider"
      style={{
        marginTop: topPx,
        marginBottom: bottomPx,
        color: colorBg,
      }}></div>
  )
}
