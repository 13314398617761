import React from 'react'

import { Row, Col, Container } from '../Layout'
import { BodyText2, Heading4, Heading5 } from '../Heading'
import {
  EventIcon,
  ForwardIcon,
  InsightIcon,
  InvestIcon,
  NewsIcon,
  NewsTitleIcon,
  TelescopeIcon,
} from '../Icon'

import { Link } from '../Link'

import './index.scss'
import { handleImgUrl } from '../../utils'
import { motion, Variants } from 'framer-motion'
import { useNewsCardTransition } from '../../animation'

export interface NewsTagProps {
  name: string
  icon?: ServerImageData
  order?: number
}

export interface NewsData {
  date: string
  title: string
  content: string
  url: string
  tag?: APIResponse<NewsTagProps>
  top: boolean
}

export const NewsTag: React.FC<NewsTagProps> = ({ name, icon }) => {
  return (
    <div className="news-tag">
      {icon ? <img src={handleImgUrl(icon.data.attributes.url)} /> : null}
      <BodyText2>{name}</BodyText2>
    </div>
  )
}

export const TagIcons = {
  trends: <TelescopeIcon />,
  news: <NewsIcon />,
  portfolio: <InvestIcon />,
  event: <EventIcon />,
  insight: <InsightIcon />,
}

export const NewsList: React.FC<{
  data: ResponseData<NewsData>[]
  title?: string
}> = ({ data = [], title }) => {
  const transition = useNewsCardTransition()
  const titleVariants: Variants = {
    initial: { y: 0, opacity: 0.8 },
    hover: { y: -8, opacity: 1 },
  }
  const arrowVariants: Variants = {
    initial: { rotate: 0, opacity: 0.5 },
    hover: { rotate: 45, opacity: 1 },
  }

  return (
    <Container className="news">
      <div className="title">
        <NewsTitleIcon />
        <Heading4>{title}</Heading4>
      </div>
      <Row className="news-list">
        {data.map((item, index) => {
          const { attributes: news, id } = item
          const tagData = news.tag ? news.tag.data : null
          return (
            <Col
              md={5}
              lg={3}
              className="news-item"
              key={id + index.toString()}
              initial="initial"
              whileHover="hover"
              transition={transition}>
              <Link to={`/${news.locale}/news/${id}`} external={true}>
                <div className="title-container">
                  <BodyText2>{news.date.replaceAll('-', '.')}</BodyText2>
                  {tagData && <NewsTag {...tagData.attributes} />}
                </div>
                <motion.div variants={titleVariants}>
                  <Heading5>{news.title}</Heading5>
                </motion.div>
                <div>
                  <motion.span
                    variants={arrowVariants}
                    className="forward-icon">
                    <ForwardIcon />
                  </motion.span>
                </div>
              </Link>
            </Col>
          )
        })}
        <Col md={5} lg={3} className="news-item hidden"></Col>
        <Col md={5} lg={3} className="news-item hidden"></Col>
        <Col md={5} lg={3} className="news-item hidden"></Col>
      </Row>
    </Container>
  )
}
