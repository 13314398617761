import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useButtonAnimate, useCardHover } from '../../animation'

import { CircleButton } from '../Button'
import { Heading6 } from '../Heading'
import { Progress } from '../Progress'

import './index.scss'

interface ScrollerProps {
  nums: number
  hideNums?: boolean
  color?: 'black' | 'violet'
  pages?: { content: React.ReactNode; footer?: React.ReactNode }[]
  gutter?: number
  children: React.ReactNode
}

export const Scroller: React.FC<ScrollerProps> = ({
  children,
  nums,
  hideNums = false,
  color = 'violet',
  pages = [],
}) => {
  const [active, setActive] = useState(0)
  const onNext: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    if (active < nums - 1) {
      setActive(active + 1)
    } else {
      setActive(0)
    }
  }

  const onPrev: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    if (active > 0) {
      setActive(active - 1)
    } else {
      setActive(nums - 1)
    }
  }
  const { variants, transition } = useButtonAnimate()
  const { variants: cardVariants, transition: cardTransition } = useCardHover()

  return (
    <motion.div
      className="scroller"
      onClick={onNext}
      initial="initial"
      whileHover="hover"
      variants={cardVariants}
      transition={cardTransition}>
      <div className="scroller-wrapper">
        {pages.length ? pages[active]?.content : children}
      </div>
      <div className="scroller-indicator">
        <Progress
          percent={
            pages.length ? ((active + 1) / pages.length) * 100 : 0
          }></Progress>
        <div className={`scroller-footer ${hideNums ? 'hide' : ''}`}>
          {!!pages.length && pages[active]?.footer}
          <div className={`change-actions ${hideNums ? 'hide' : ''}`}>
            <Heading6>
              <span className="active">
                {(active + 1).toString().padStart(2, '0')}
              </span>
              /<span>{nums.toString().padStart(2, '0')}</span>
            </Heading6>
            <div className="actions">
              <CircleButton
                direction="left"
                color={color}
                onClick={onPrev}
                initial="initial"
                whileHover="hover"
                whileTap="tap"
                variants={variants}
                transition={transition}></CircleButton>
              <CircleButton
                direction="right"
                color={color}
                onClick={onNext}
                initial="initial"
                whileHover="hover"
                whileTap="tap"
                variants={variants}
                transition={transition}></CircleButton>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
