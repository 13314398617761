import React from 'react'
import { Helmet } from 'react-helmet'
import { motion } from 'framer-motion'

import { Container, Layout } from '../../components/Layout'

import { Heading1, newLine } from '../../components/Heading'
import { ReportData, Reports } from '../../components/Reports'
import { NewsData } from '../../components/NewsList'

import { FounderCard, FounderCardProps } from './founder'
import { IndustryData, IndustryResearch } from './IndustryResearch'
import { Report } from './report'
import { useHomeTransition } from '../../animation'
import { sortTop } from '../../utils/pagination'
import { DesktopList } from '../News'

import './index.scss'

export const HomePage: React.FC<
  PageData<{
    home: APIResponse<{
      title: string
      slogan: string
      founders: string
      reports: string
      industry: string
      news: string
    }>
    newsList: APIListResponse<NewsData>
    founders: FounderCardProps['data']
    industries: IndustryData['data']
    reports: ReportData
  }>
> = ({ pageContext }) => {
  const {
    serverData: { newsList, founders, industries, reports, home },
  } = pageContext
  const transition = useHomeTransition()
  sortTop(newsList.data)

  return (
    <Layout className="home-page" motionX={true}>
      <Helmet>
        <title>{home.data.attributes.title}</title>
      </Helmet>
      <Container className="overview">
        <motion.div
          initial={{ opacity: 0, y: 128 }}
          animate={{ opacity: 1, y: 0 }}
          transition={transition}
          className="deer"></motion.div>
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          className="heading"
          animate={{ opacity: 1, y: -128 }}
          transition={{ ...transition }}>
          <Heading1>{newLine(home.data.attributes.slogan)}</Heading1>
        </motion.div>
      </Container>

      <Container>
        <Report />
      </Container>

      <FounderCard data={founders} title={home.data.attributes.founders} />
      <Reports reports={reports} title={home.data.attributes.reports} />
      <IndustryResearch
        data={industries}
        title={home.data.attributes.industry}
      />
      <Container className="news">
        <DesktopList
          title={home.data.attributes.news}
          data={newsList}
          filterOptions={[]}
          pageSize={12}></DesktopList>
      </Container>
    </Layout>
  )
}

export default HomePage
