import React from 'react'

import { Container, Row, Col } from '../../../components/Layout'

import { Heading4, Heading5, Subtitle3 } from '../../../components/Heading'
import { ChatIcon, QuoteIcon } from '../../../components/Icon'
import { Scroller } from '../../../components/Scroller'
import { useCollapse } from '../../../components/Collapse'
import { handleImgUrl } from '../../../utils'
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion'

import './index.scss'

interface FounderCardItemProps {
  title: string
  subtitle: string
  words: string[]
  avatar: React.ReactNode
  logo: string
}

const variants: Variants = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}
const transition: Transition = {
  stiffness: 400,
  damping: 40,
  mass: 1,
  delay: 0,
  type: 'spring',
}

export const FounderCardItem: React.FC<FounderCardItemProps> = ({
  avatar,
  title,
  words,
  subtitle,
  logo,
}) => {
  const { collapse, Marker } = useCollapse()

  return (
    <div className="founder-card-item">
      <Row style={{ justifyContent: 'space-between' }}>
        <Col
          md={7}
          variants={variants}
          transition={transition}
          animate="animate"
          exit="exit">
          <Row className="words-wrap">
            <Row
              style={{ flexDirection: 'column' }}
              className={`words ${collapse ? 'line-clamp' : ''}`}>
              <QuoteIcon direction="top" />
              {words.map((word, index) => {
                return <p key={index.toString()}>{word}</p>
              })}
              <QuoteIcon direction="down" />
            </Row>
            {Marker}
          </Row>
        </Col>
        <Col md={5} className="avatar-wrap">
          <motion.div
            className="avatar-wrap"
            variants={variants}
            transition={transition}
            animate="animate"
            exit="exit">
            <div className="avatar">{avatar}</div>
            <Heading5>{title}</Heading5>
          </motion.div>
        </Col>
      </Row>
      <Row className="name-wrap">
        <Col md={5}>
          <div className="name">
            <img src={handleImgUrl(logo)} className="logo" />
            <Subtitle3>{subtitle}</Subtitle3>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export interface FounderResponse {
  title: string
  subtitle: string
  avatar: ServerImageData
  logo: ServerImageData
  description: Paragraph[]
}
export interface FounderCardProps {
  data: APIListResponse<FounderResponse>
  title: string
}

export const FounderCard: React.FC<FounderCardProps> = ({ data, title }) => {
  const names = data.data.map((founder, index) => {
    return {
      content: (
        <AnimatePresence>
          <FounderCardItem
            key={founder.id + index.toString()}
            title={founder.attributes.title}
            subtitle={founder.attributes.subtitle}
            logo={founder.attributes.logo.data?.attributes.url}
            avatar={
              <img
                src={handleImgUrl(
                  founder.attributes.avatar.data.attributes.url
                )}
              />
            }
            words={founder.attributes.description.map((p) => p.description)}
          />
        </AnimatePresence>
      ),
      footer: (
        <AnimatePresence exitBeforeEnter>
          <Row className="name-wrap" key={founder.id}>
            <Col
              md={7}
              variants={variants}
              transition={transition}
              initial="initial"
              animate="animate"
              exit="exit">
              <div className="name">
                {founder.attributes.logo.data?.attributes.url && (
                  <img
                    src={handleImgUrl(
                      founder.attributes.logo.data?.attributes.url
                    )}
                    className="logo"
                  />
                )}

                <Subtitle3>{founder.attributes.subtitle}</Subtitle3>
              </div>
            </Col>
          </Row>
        </AnimatePresence>
      ),
    }
  })
  return (
    <Container className="founder-card">
      <div className="title">
        <ChatIcon />
        <Heading4>{title}</Heading4>
      </div>
      <Scroller
        nums={data.meta.pagination.total}
        color="black"
        pages={names}
        gutter={0}></Scroller>
    </Container>
  )
}
