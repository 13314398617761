import React, { useRef } from 'react'
import { motion, Variants } from 'framer-motion'

import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { BodyText2, Heading4 } from '../../../components/Heading'
import { ReportIcon } from '../../../components/Icon'
import { Col, Container } from '../../../components/Layout'
import { useNewsCardTransition } from '../../../animation'
import { Slider } from '../../../components/Slider'
import { useIsMd } from '../../../utils/media'

import './index.scss'
import { Link } from '../../../components/Link'
import { NewsTagProps } from '../../../components/NewsList'
import { useTranslation } from '../../../i18n'

interface ResearchItemProps {
  gutter?: boolean
  title: string
  content: string
  index: number
  url: string
}

export const ResearchItem: React.FC<ResearchItemProps> = ({
  gutter = true,
  title,
  content,
  index,
  url,
}) => {
  const transition = useNewsCardTransition()
  const headerVariants: Variants = {
    initial: {
      y: 0,
    },
    hover: {
      y: -8,
    },
  }
  const mod = index % 4
  return (
    <Link external={true} to={url}>
      <Col
        md={6}
        lg={4}
        className={`research-item bg-0${mod ? mod : 4}`}
        gutter={gutter}
        initial="initial"
        whileHover="hover"
        transition={transition}>
        <BodyText2>{title}</BodyText2>
        <Divider top={18} bottom={24} color="var(--color-gray-900)"></Divider>

        <motion.div variants={headerVariants}>
          <Heading4>{content}</Heading4>
        </motion.div>
      </Col>
    </Link>
  )
}

export interface IndustryData {
  data: APIListResponse<{
    tag: APIResponse<NewsTagProps>
    title: string
    content: string
  }>
  title: string
}

export const IndustryResearch: React.FC<IndustryData> = ({ data, title }) => {
  const ref = useRef<HTMLDivElement>(null)
  const moreVariants: Variants = {
    initial: {
      opacity: 1,
    },
    hover: {
      opacity: 0.8,
    },
    tap: {
      opacity: 1,
    },
  }
  const transition = useNewsCardTransition()
  const isMd = useIsMd()
  const { t } = useTranslation({ zh: {}, en: {} })

  return (
    <Container className="industry-research">
      <div className="title-container">
        <div className="title">
          <ReportIcon />
          <Heading4>{title}</Heading4>
        </div>
        {/* <div className="desktop-button">
          <Button
            border
            initial="inital"
            variants={moreVariants}
            whileHover="hover"
            whileTap="tap"
            transition={transition}>
            <BodyText2>{t('more')}</BodyText2>
          </Button>
        </div> */}
      </div>
      <div ref={ref} className="series">
        <Slider container={ref} padding={24} drag={isMd}>
          {data.data.map((item, index) => {
            return (
              <ResearchItem
                key={item.id}
                title={item.attributes.tag.data?.attributes.name}
                content={item.attributes.title}
                index={index + 1}
                url={`/${item.attributes.locale}/insights/${item.id}`}
              />
            )
          })}
        </Slider>
      </div>
      <div className="mobile-button">
        <Button>
          <BodyText2>{t('more')}</BodyText2>
        </Button>
      </div>
    </Container>
  )
}
