import { FC } from 'react'
import './index.scss'

export const Report: FC = (props) => {
  const { children } = props

  return (
    <div className="report-banner-container">
      <a href="./reports/13/" target="_blanket">
        <video
          playsInline
          muted
          autoPlay
          loop
          src="https://chuxin-strapi-media.oss-cn-beijing.aliyuncs.com/Banner_AI_Report_d9dfdb97de.mp4"></video>
        {children}
      </a>
    </div>
  )
}
